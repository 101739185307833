import React from 'react';
import DevelopmentServiceSection from '../components/development-service-section';
import PageLayout from '../components/page-layout';
import ServiceSection from '../components/service-section';
import SimpleBanner from '../components/simple-banner';
import { graphql } from 'gatsby';
import SEO from '../components/seo';

export default function DevelopmentPage({ data }) {
  const { banner } = data;

  return <PageLayout pageKey="/development">
    <SEO title="开发与代运营" />

    <SimpleBanner
      title="产品设计、开发与代运营"
      image={banner.childImageSharp.fixed}
      contentStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
    />
    <DevelopmentServiceSection />
    <ServiceSection />
  </PageLayout>
}

export const query = graphql`
  query {
    banner: file(relativePath: {eq: "banner-dev.jpg"}) {
      childImageSharp {
        fixed(width: 1440, height: 595) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
  }
`;
